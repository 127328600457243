import React, { useState, useEffect, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { IOrder } from '../interface/Order';
import { IAddress } from '../interface/Address';
import { IHeaders } from '../interface/Headers';
import Modal from '~/components/Modal';
import api from '~/services/api';
import { normalizeCep, productUrl } from '~/utils/normalize';
import { states } from '~/utils/states';
import TagManager from 'react-gtm-module';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
  activeModalAutentication: (modal: boolean) => void;
  getSubmitedData: (data: any) => void; // eslint-disable-line
}

interface IValue {
  name: string;
  zip_code: string;
  street: string;
  district: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  default: number;
}

const Address: React.FC<IProps> = ({
  order,
  headersTokens,
  getSubmitedData,
  activeModalAutentication,
}) => {
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [address, setAddress] = useState<IAddress>({ id: 0 });
  const [editAddress, setEditAddress] = useState<IAddress>({ id: 0 });
  const [modalEditAddress, setModalEditAddress] = useState<boolean>(false);
  const [modalAddress, setModalAddress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [values, setValues] = useState<IValue>({
    name: '',
    zip_code: '',
    street: '',
    district: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    default: 0,
  });

  async function loadAddresses() {
    const { data: dataAddresses } = await api.get(
      `customer/me/addresses`,
      headersTokens,
    );
    setAddresses(dataAddresses);
  }

  useEffect(() => {
    if (order.orderDeliveryAddress) {
      setAddress(order.orderDeliveryAddress.address);
    }
    loadAddresses();
    setModalAddress(false);
  }, [order]); // eslint-disable-line

  function handleModalAutentication(modal: boolean) {
    return activeModalAutentication(modal);
  }

  function handleSubmit(data: IAddress) {
    setAddress(data);
    return getSubmitedData(data.id);
  }

  async function searchAddress() {
    try {
      if (values.zip_code.length === 9) {
        const { data } = await api.get(
          `address/${values.zip_code.replace(/[^\d]/g, '')}`,
          headersTokens,
        );
        setValues({
          ...values,
          street: data.street,
          city: data.city,
          district: data.district,
          state: data.state,
          number: '',
          complement: '',
        });
        document.getElementById('number_address')?.focus();
      }
    } catch (error) {} // eslint-disable-line
  }

  async function searchAddressEdit() {
    try {
      if (editAddress?.zip_code?.length === 9) {
        const { data } = await api.get(
          `address/${editAddress.zip_code.replace(/[^\d]/g, '')}`,
          headersTokens,
        );
        setEditAddress({
          ...editAddress,
          street: data.street,
          city: data.city,
          district: data.district,
          state: data.state,
          number: '',
          complement: '',
        });
        document.getElementById('number_address_edit')?.focus();
      }
    } catch (error) {} // eslint-disable-line
  }

  async function handleSubmitNewAddress(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setErrorMessage('');

    try {
      await api.post(
        `customer/addresses`,
        {
          name: values.name,
          zip_code: values.zip_code.replace(/[^\d]/g, ''),
          street: values.street,
          district: values.district,
          number: values.number,
          complement: values.complement,
          city: values.city,
          state: values.state,
          default: Number(values.default),
        },
        headersTokens,
      );
      await loadAddresses();
      setModalAddress(false);
    } catch (erro: any) {
      if (erro.response.data.length) {
        setErrorMessage(erro.response.data[0].message);
      } else {
        setErrorMessage(erro.response.data.message);
      }
    }
  }

  async function handleSubmitEditAddress(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setErrorMessage('');

    try {
      await api.put(
        `customer/addresses/${editAddress.id}`,
        {
          name: editAddress.name || '',
          zip_code: editAddress?.zip_code?.replace(/[^\d]/g, '') || '',
          street: editAddress.street || '',
          district: editAddress.district || '',
          number: editAddress.number || '',
          complement: editAddress.complement || '',
          city: editAddress.city || '',
          state: editAddress.state || '',
          default: editAddress.default || 0,
        },
        headersTokens,
      );
      await loadAddresses();
      handleSubmit(editAddress);
      setModalEditAddress(false);
    } catch (erro: any) {
      if (erro.response.data.length) {
        setErrorMessage(erro.response.data[0].message);
      } else {
        setErrorMessage(erro.response.data.message);
      }
    }
  }

  const addShippingInfo = useCallback(
    (deliveryMethod: string | undefined, chosenStore?: string | undefined) => {
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'add_shipping_info',
          ecommerce: {
            currency: 'BRL',
            value: parseFloat((order.total / 100).toFixed(2)),
            shipping_tier: deliveryMethod,
            loja_retirada: chosenStore || '',
            items: order.orderProducts?.map(orderProducts => ({
              item_id: orderProducts?.product?.sku,
              item_name: orderProducts?.product?.name,
              item_brand: orderProducts?.product?.brand?.name,
              item_category: orderProducts?.product?.categories[0]?.name,
              price: parseFloat(
                (orderProducts?.product?.price_end / 100).toFixed(2),
              ),
              quantity: orderProducts?.qtd,
              is_in_stock: 'in_stock',
              product_image: orderProducts?.product?.photos[0].path,
              product_url: productUrl(
                orderProducts?.product?.sku,
                orderProducts?.product?.name,
              ),
            })),
          },
        },
      });
    },
    [order.total, order.orderProducts],
  );

  // eslint-disable-next-line no-shadow
  const getSelectedStoreAndDeliveryType = (order: IOrder) => {
    return order.orderDeliveries?.reduce(
      (acc: any, delivery) => {
        const selectedOption = delivery.deliveryOptions.find(
          option => option.is_selected,
        );

        if (selectedOption) {
          acc.storeName = selectedOption.store?.name || acc.storeName;
          acc.deliveryType = selectedOption.delivery_type || acc.deliveryType;
        }

        return acc;
      },
      { storeName: null, deliveryType: null },
    );
  };

  const { storeName, deliveryType } = getSelectedStoreAndDeliveryType(order);

  return (
    <div>
      {modalAddress && (
        <Modal size="big">
          <p>Novo endereço</p>
          <form onSubmit={handleSubmitNewAddress}>
            {errorMessage !== '' && (
              <p className="alert alert-danger">{errorMessage}</p>
            )}
            <Row>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>Nome do Endereço</Form.Label>
                  <Form.Control
                    name="name"
                    placeholder="Ex: Casa, Trabalho, etc"
                    onChange={e => {
                      setValues({ ...values, name: e.target.value });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={3}>
                <Form.Group>
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    name="zip_code"
                    value={values.zip_code}
                    placeholder="00000-000"
                    onBlur={() => searchAddress()}
                    onChange={e => {
                      setValues({
                        ...values,
                        zip_code: normalizeCep(e.target.value),
                      });
                    }}
                  />
                </Form.Group>
                <Form.Text>
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="blank"
                  >
                    Não sabe o CEP?
                  </a>
                </Form.Text>
              </Col>
              <Col className="mt-2" lg={7}>
                <Form.Group>
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    name="street"
                    placeholder="Rua, Avenida, etc"
                    value={values.street}
                    onChange={e => {
                      setValues({ ...values, street: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={2}>
                <Form.Group>
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    name="number"
                    id="number_address"
                    value={values.number}
                    onChange={e => {
                      setValues({ ...values, number: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={2}>
                <Form.Group>
                  <Form.Label>Compl.</Form.Label>
                  <Form.Control
                    name="complement"
                    value={values.complement}
                    onChange={e =>
                      setValues({ ...values, complement: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={4}>
                <Form.Group>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    name="district"
                    value={values.district}
                    onChange={e => {
                      setValues({ ...values, district: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={4}>
                <Form.Group>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    name="city"
                    value={values.city}
                    onChange={e => {
                      setValues({ ...values, city: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={2}>
                <Form.Group>
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    name="state"
                    value={values.state}
                    onChange={e => {
                      setValues({ ...values, state: e.target.value });
                    }}
                    as="select"
                  >
                    <option value="">Selecionar</option>
                    {states.map(state => (
                      <option value={state.uf} key={state.uf}>
                        {state.uf}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={12}>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="main_address"
                    label="Endereço Principal"
                    checked={values.default === 1}
                    onChange={() =>
                      setValues({
                        ...values,
                        default: values.default === 0 ? 1 : 0,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button
                className="btn btn-imperio pb-2 pl-4 pr-4 pt-2 btn-block"
                type="submit"
              >
                Salvar
              </button>
              <button
                className="btn btn-ligth ml-2 pb-2 pl-4 pr-4 pt-2 btn-block"
                type="button"
                onClick={() => setModalAddress(false)}
              >
                Cancelar
              </button>
            </div>
          </form>
        </Modal>
      )}
      {modalEditAddress && (
        <Modal size="big">
          <p>Editar endereço</p>
          <form onSubmit={handleSubmitEditAddress}>
            {errorMessage !== '' && (
              <p className="alert alert-danger">{errorMessage}</p>
            )}
            <Row>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>Nome do Endereço</Form.Label>
                  <Form.Control
                    name="name"
                    value={editAddress.name || ''}
                    placeholder="Ex: Casa, trabalho, etc"
                    onChange={e => {
                      setEditAddress({ ...editAddress, name: e.target.value });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={3}>
                <Form.Group>
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    name="zip_code"
                    value={normalizeCep(String(editAddress.zip_code || ''))}
                    placeholder="00000-000"
                    onBlur={() => searchAddressEdit()}
                    onChange={e => {
                      setEditAddress({
                        ...editAddress,
                        zip_code: normalizeCep(e.target.value),
                      });
                    }}
                    required
                  />
                  <Form.Text>
                    <a
                      href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                      target="blank"
                    >
                      Não sabe o CEP?
                    </a>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={7}>
                <Form.Group>
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    name="street"
                    value={editAddress.street || ''}
                    placeholder="Rua, Avenida, etc"
                    onChange={e => {
                      setEditAddress({
                        ...editAddress,
                        street: e.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={2}>
                <Form.Group>
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    name="number"
                    id="number_address_edit"
                    value={editAddress.number || ''}
                    onChange={e => {
                      setEditAddress({
                        ...editAddress,
                        number: e.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={2}>
                <Form.Group>
                  <Form.Label>Compl.</Form.Label>
                  <Form.Control
                    name="complement"
                    value={editAddress.complement || ''}
                    onChange={e =>
                      setEditAddress({
                        ...editAddress,
                        complement: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={4}>
                <Form.Group>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    name="district"
                    value={editAddress.district || ''}
                    onChange={e => {
                      setEditAddress({
                        ...editAddress,
                        district: e.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={4}>
                <Form.Group>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    name="city"
                    value={editAddress.city || ''}
                    onChange={e => {
                      setEditAddress({ ...editAddress, city: e.target.value });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={2}>
                <Form.Group>
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    name="state"
                    value={editAddress.state || ''}
                    onChange={e => {
                      setEditAddress({ ...editAddress, state: e.target.value });
                    }}
                    required
                    as="select"
                  >
                    <option value="">Selecionar</option>
                    {states.map(state => (
                      <option value={state.uf} key={state.uf}>
                        {state.uf}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-2" lg={12}>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="edit_main_checkbox"
                    label="Endereço Principal"
                    checked={editAddress.default === 1}
                    onChange={() =>
                      setEditAddress({
                        ...editAddress,
                        default: editAddress.default === 1 ? 0 : 1,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button
                className="btn btn-imperio pb-2 pl-4 pr-4 pt-2"
                type="submit"
              >
                Salvar
              </button>
              <button
                className="btn btn-ligth ml-2 pb-2 pl-4 pr-4 pt-2 btn-block"
                type="button"
                onClick={() => {
                  setEditAddress({ id: 0 });
                  setModalEditAddress(false);
                }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </Modal>
      )}
      {!order.loading ? (
        <>
          {addresses.map(e => {
            return (
              <div className="addressList" key={e.id}>
                <button
                  type="button"
                  className={e.id === address.id ? 'active' : ''}
                  onClick={() => {
                    handleSubmit(e);
                    addShippingInfo(deliveryType, storeName);
                  }}
                >
                  .
                </button>
                <p>
                  {e.street}, {e.number} {e.complement} - {e.district}, {e.city}{' '}
                  - {e.state},{' '}
                  {order.mask ? e.zip_code : normalizeCep(String(e.zip_code))}
                </p>
                <button
                  type="button"
                  className="edit"
                  onClick={() => {
                    if (order.mask) {
                      handleModalAutentication(true);
                    } else {
                      setEditAddress(e);
                      setModalEditAddress(true);
                    }
                  }}
                >
                  Editar
                </button>
              </div>
            );
          })}
          <button
            type="button"
            onClick={() => {
              if (order.mask) {
                handleModalAutentication(true);
              } else {
                setModalAddress(true);
              }
            }}
            className="btn btn-imperio btn-small"
          >
            Adicionar novo endereço
          </button>
        </>
      ) : (
        <p className="textLoading" />
      )}
    </div>
  );
};

export default Address;
